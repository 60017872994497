export const isValidEmail  = (email) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)

export const isValidPassword  = (password) => /^[a-zA-Z0-9]{8,}$/.test(password)

export const isValidUser = () => {
  if(typeof document === 'undefined')
    return false
  const cookies = document.cookie!== "" && document.cookie
    .split(';')
    .map(cookie => cookie.split('='))
    .map(([key, value]) => ({[key.trim()]: value.trim()}))
    .reduce((acc, cookie) => ({...acc, ...cookie}), {})
  return cookies.credential === 'w8tI31WhHni'
}

