import React, { useEffect, useState } from "react"
import { navigate } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'
import * as styles from './styles.module.css'
import { Link } from "gatsby"
import { getSession, logout } from '../../reducers/actions/user.js'
import withLocation from '../../HOC/withLocation.js'
import { isValidUser } from '../../utils/common.js'
import Logo from '../../images/crossoffer-logo.png'

const UserOptions = () => (
  <div className={styles.userMenu} >
    <a  onClick={() => navigate('/login/')} >
      Login
    </a>
    <a onClick={() => navigate('/register/')} >
      Registro
    </a>
  </div>
)


const UserLoggedOptions = () => {
  const dispatch = useDispatch()
  const logoutRequest = logout(dispatch) 
  return (
    <div className={styles.userLoggedOptionsContainer}>
      <div className={styles.dropdown} >
        <button>User</button>
        <div className={styles.dropdownContent}>
          <Link to={'/profile/'}>Perfil</Link>
          <Link to={'/provider-profile/'}>Perfil como proveedor</Link>
          <a onClick={logoutRequest} >
            Salir
          </a>
        </div>
      </div>
    </div>
  )
}

const Header = ({location}) => {
  if (typeof window !== 'undefined' && process.env.GATSBY_BUILDING === 'true' && !isValidUser() && location.pathname !== '/') {
    navigate('/')
  }
  const [isFirstRender, setIsFirstRender] = useState(true)
  const dispatch = useDispatch()
  
  useEffect(() => {
    if (isFirstRender) {
      getSession(dispatch)(location.pathname)
      setIsFirstRender(false)
    }
  })
  const loginState = useSelector(state => state.userReducer?.login || {});
  
  return (
    <header className={styles.headerBackground || ''}>
      <Link to='/' >
        <img className={styles.logo} src={Logo} alt="Cross Offer Logo" />
      </Link>
      <div className={styles.content} >
      {!loginState.response && <UserOptions />}
      {!!loginState.response && <UserLoggedOptions />}
      </div>
    </header>
  )
}

export default withLocation(Header)
