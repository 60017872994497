import {
  register as registerType,
  login as loginType,
  logout as logoutType,
  update as updateType,
  getById as getUserById,
  get as getUsers,
  getAndConcat as getUsersAndConcat
} from '../types/user.js'
import { navigate } from '@reach/router'

const baseURL = process.env.GATSBY_API_URL;

const fetchInstance = async (url, options = {}) => {
  const response = await fetch(`${baseURL}${url}`, {
    credentials: "include",
    ...options,
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const register = dispatch => async (user) => {
  dispatch({ type: registerType.REQUEST, data: user });
  try {
    const data = await fetchInstance('user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });
    dispatch({ type: registerType.SUCCESS, data });
  } catch (error) {
    dispatch({ type: registerType.FAIL });
  }
};

export const cleanRegister = dispatch => {
  dispatch({ type: registerType.CLEAN });
};

export const login = dispatch => async (user) => {
  dispatch({ type: loginType.REQUEST });
  try {
    const data = await fetchInstance('/user/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });
    dispatch({ type: loginType.SUCCESS, data });
  } catch (error) {
    dispatch({ type: loginType.FAIL });
  }
};

export const getSession = dispatch => async (pathname) => {
  dispatch({ type: loginType.REQUEST });
  try {
    const data = await fetchInstance('user/login', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    dispatch({ type: loginType.SUCCESS, data });
  } catch (error) {
    dispatch({ type: loginType.FAIL });
    console.log(pathname);
    if (
      pathname === '/new-project/' ||
      pathname === '/profile/' ||
      pathname === '/project-details/' ||
      pathname === '/proposal' ||
      pathname === '/provider-profile/'
    ) {
      navigate('/login');
    }
  }
};

export const update = dispatch => async (payload) => {
  dispatch({ type: updateType.REQUEST });
  try {
    const data = await fetchInstance('user', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    dispatch({ type: updateType.SUCCESS, data });
  } catch (error) {
    dispatch({ type: updateType.FAIL });
  }
};

export const getById = dispatch => async (userId) => {
  dispatch({ type: getUserById.REQUEST });
  try {
    const data = await fetchInstance(`/user/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    dispatch({ type: getUserById.SUCCESS, data });
  } catch (error) {
    dispatch({ type: getUserById.FAIL });
  }
};

export const get = dispatch => async (queryParams) => {
  const queryString = Object.keys(queryParams)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
    .join('&');
  
  dispatch({ type: getUsers.REQUEST });
  try {
    const data = await fetchInstance(`/user?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    dispatch({ type: getUsers.SUCCESS, data });
  } catch (error) {
    dispatch({ type: getUsers.FAIL });
  }
};

export const getAndConcat = dispatch => async (queryParams) => {
  const queryString = Object.keys(queryParams)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
    .join('&');
  
  dispatch({ type: getUsersAndConcat.REQUEST });
  try {
    const data = await fetchInstance(`/user?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    dispatch({ type: getUsersAndConcat.SUCCESS, data });
  } catch (error) {
    dispatch({ type: getUsersAndConcat.FAIL });
  }
};

export const logout = dispatch => async () => {
  dispatch({ type: logoutType.REQUEST });
  try {
    await fetchInstance('user/logout', { method: 'HEAD' });
    dispatch({ type: logoutType.SUCCESS });
  } catch (error) {
    dispatch({ type: logoutType.FAIL });
  }
};
